import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './PageHeader.module.scss'

const PageHeader = ({ title }) => {
  const isMainPage = title === 'World of Data'
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            {isMainPage ? (
              <h1>World of Data</h1>
            ) : (
              <h1>Explore {title} of the world</h1>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
