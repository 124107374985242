import React, { useState, useEffect } from 'react'
import { Select, Skeleton, TextInput } from '@mantine/core'
import styles from './Grid.module.scss'
import { Link } from 'gatsby'
import _ from 'lodash'
import ReactCountryFlag from 'react-country-flag'
import countries from 'i18n-iso-countries'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const Grid = ({ pages, category }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredFormats, setFilteredFormats] = useState(pages)
  const [filterLetters, setFilterLetters] = useState([])

  useEffect(() => {
    const filtered = pages.filter(format => {
      const title = format.title.toLowerCase()
      return title.includes(searchTerm.toLowerCase())
    })
    setFilteredFormats(filtered)
  }, [pages, searchTerm])

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleFilter = letter => {
    if (filterLetters.includes(letter)) {
      setFilteredFormats(pages)
      setFilterLetters([])
      return
    } else {
      const filtered = pages.filter(page => {
        const firstLetter = page.title.charAt(0).toLowerCase()
        return firstLetter === letter.toLowerCase()
      })
      setFilteredFormats(filtered)
      setFilterLetters([letter])
    }
  }

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.filter}>
            <TextInput
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              radius="lg"
              size="xl"
              classNames={{ input: styles.searchInput }}
            />
          </div>
          <div>
            <Skeleton visible={!pages}>
              <div className={styles.grid}>
                {(() => {
                  const startsWithMatches = pages
                    .filter(c => {
                      const lowerCaseCountry = c.title.toLowerCase()
                      const lowerCaseSearchTerm = searchTerm.toLowerCase()
                      return lowerCaseCountry.startsWith(lowerCaseSearchTerm)
                    })
                    .sort((a, b) => a.title.localeCompare(b.title))

                  if (startsWithMatches.length > 0) {
                    return startsWithMatches
                  }

                  return pages
                    .filter(c => {
                      const lowerCaseCountry = c.title.toLowerCase()
                      const lowerCaseSearchTerm = searchTerm.toLowerCase()
                      return lowerCaseCountry.includes(lowerCaseSearchTerm)
                    })
                    .sort((a, b) => a.title.localeCompare(b.title))
                })().map(c => (
                  <GridItem
                    key={c.title}
                    title={c.title}
                    link={`/explore${
                      category ? `/${category}` : ''
                    }/${_.kebabCase(c.title)}/`}
                  />
                ))}
              </div>
            </Skeleton>
          </div>
        </div>
      </div>
    </>
  )
}

const GridItem = ({ title, link }) => {
  const [hover, setHover] = useState(false)
  const countryCode = countries.getAlpha2Code(title, 'en')

  return (
    <Link to={link}>
      <div
        className={styles.postCard}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div className={`${styles.cardContent} ${hover && styles.hovered}`}>
          <div className={styles.cardContentInner}>
            <ReactCountryFlag
              countryCode={countryCode}
              svg
              aria-label={title}
              style={{
                width: '32px',
                height: '24px',
                borderRadius: '5px'
              }}
            />
            <h5>{title}</h5>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Grid
